@keyframes starjump {
  0% {
    transform: translateY(0)
  }
  40% {
    transform: translateY(-20%)
  }
  100% {
    transform: translateY(0)
  }
}
.star-rating-jump {
  animation: starjump 0.2s cubic-bezier(0, 0, 0.5, 0.4);
}
$text-primary: rgba(255, 255, 255, 0.95);
$text-secondary: rgba(255, 255, 255, 0.7);

:root {
  --clr-future-purple: #9670f9;
  --clr-techy-green: #65e3ab;
  --clr-stardust--yellow: #f6e829;
  --clr-business-blue: #92dcf0;
  --clr-hr-pink: #ffbdcb;
  --clr-blood-orange: #ff634d;
  --clr-midnight-blue: #051d3e;
  --clr-light-grey: #efefef;
  --clr-purple: var(--clr-future-purple);
}

@mixin shadow-1($with-hover-effect) {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 0.5rem;
  transition: border-color ease-in 150ms;
  @if $with-hover-effect {
    &:hover {
      border-color: var(--clr-purple);
    }
  }
}

@layer components {
  .input {
    @apply w-full
      rounded-md
      border-2
      border-gray-800
      bg-midnight-blue
      px-2
      py-2
      text-black
      text-white
      shadow-md
      outline-2
      outline-gray-600
      hover:border-gray-700
      focus:border-gray-600;
  }
}

@import "form.css";
@import "modal.css";
@import "star-rating.css";
@import "snackbar.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    background: linear-gradient(135deg, #121218, #281828);
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 40px #051d3e inset !important;
    box-shadow: 0 0 0 40px #051d3e inset !important;
    @apply text-base;
  }

  input:-webkit-autofill {
    -webkit-text-fill-color: white !important;
  }
}

@layer components {
  .content {
    p {
      @apply mb-4 text-base;
    }
    h2 {
      @apply mt-8 mb-4 font-outfit text-3xl font-medium;
    }
    ul,
    ol {
      @apply mb-4 ml-8 list-outside;
    }
    ul {
      @apply list-disc;
    }
    ol {
      @apply list-decimal;
    }
    *:last-child {
      @apply mb-0;
    }
  }
  .custom-select {
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg%20width%3D'800'%20height%3D'800'%20viewBox%3D'0%200%2048%2048'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%3E%3Cpath%20style%3D'color%3A%23000%3Bfill%3A%23535353%3Bstroke-width%3A0%3B'%20d%3D'M5.02%2011.581a2.427%202.427%200%200%200-1.715.711%202.427%202.427%200%200%200%200%203.432L24%2036.419l20.695-20.695a2.427%202.427%200%200%200%200-3.432%202.427%202.427%200%200%200-3.432%200L24%2029.555%206.737%2012.292a2.427%202.427%200%200%200-1.716-.71Z'%2F%3E%3C%2Fsvg%3E");
    background-repeat: no-repeat;
    background-position: right 0.75rem top 50%, 0 0;
    background-size: 1rem auto, 100%;
  }
}

@layer utilities {
  .material-symbols-rounded {
    font-variation-settings: "FILL" 0, "wght" 300, "GRAD" 0, "opsz" 24;
  }
  .animate-loading {
    animation: spin 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  }
  .animation-delay--150 {
    animation-delay: -150ms;
  }
  .animation-delay--300 {
    animation-delay: -300ms;
  }
  .animation-delay--450 {
    animation-delay: -450ms;
  }
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}

@import "variables";

.percentile {
  display: block;
  position: relative;

  &--clickable {
    cursor: pointer;
    background-color: var(--clr-future-purple);
  }

  svg {
    position: relative;
    //z-index: 10;
  }

  &__foldout {
    padding: 0 1rem;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    color: white;

    transition: opacity 0s, height 0.2s, box-shadow 0.2s;

    user-select: none;
  }

  .stenpath {
    stroke: transparent;
    fill: var(--clr-future-purple);
    &--active {
      fill: black;
    }
  }

  &--open {
    svg {
      z-index: 11;
    }
    .percentile__foldout {
      height: auto;
      opacity: 1;
      transform: translateY(0);
      transition: opacity 0.2s ease-in, height 0.2s, box-shadow 0.2s;
      z-index: 10;
      p {
        margin-top: 20%;
      }
    }
    .stenpath {
      fill: var(--clr-business-blue);
      &--active {
        fill: var(--clr-stardust--yellow);
      }
    }
  }

  .normpath {
    stroke: var(--clr-techy-green);
    fill: none;
  }
  .pointcircle {
    stroke: white;
    fill: var(--clr-future-purple);
  }

  &__label {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    user-select: none;
  }

  &__number {
    position: absolute;
    top: -3.5rem;
    margin: 0;
    font-weight: bold;
    font-size: 1.5rem;
    color: white;
    text-align: center;
  }
  &__labeltext {
    position: absolute;
    top: -2rem;
    font-size: 0.875rem;
    color: rgba(0, 0, 0, 0.4);
  }

  @media (max-width: 30rem) {
    &--open {
      .percentile__foldout {
        height: auto;
      }
    }
    &__number {
      font-size: 1rem;
      top: -3rem;
    }
    &__labeltext {
      font-size: 0.75rem;
      top: -2.25rem;
    }
  }

  @media (max-width: 40rem) {
    &__number {
      top: -2rem;
    }
    &__labeltext {
      top: -1.25rem;
    }
  }
}
